<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="500"
    :title="$t('admin.modifyVendorId')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form @submit.prevent="onSubmit" :disabled="loading">
        <v-card-text>
          <cz-form-field :label="$t('admin.modifyVendorIdDialog.fromVendorId')">
            <validation-provider
              :rules="'required|length:9'"
              :name="$t('admin.modifyVendorIdDialog.fromVendorId')"
              v-slot="{ errors }"
            >
              <cz-autocomplete
                v-model="form.fromVendorId"
                :hint="$t('common.autocomplete')"
                hide-no-data
                :error-messages="errors"
                no-filter
                :append-icon="null"
                :items="vendors"
                :loading="serachingVendors"
                :placeholder="$t('customer.orders.vendorSearchPlaceholder')"
                v-debounce:600ms="onVendorsSearch"
                item-text="businessName"
                item-value="_id"
                clearable
              >
                <template #item="{ item }">
                  {{ `${item.businessName} (${item._id})` }}
                </template>
                <template #selection="{ item }">
                  {{ `${item.businessName} (${item._id})` }}
                </template>
              </cz-autocomplete>
            </validation-provider>
          </cz-form-field>
          <cz-form-field :label="$t('admin.modifyVendorIdDialog.toVendorId')">
            <validation-provider
              :rules="'required|length:9'"
              :name="$t('admin.modifyVendorIdDialog.toVendorId')"
              v-slot="{ errors }"
            >
              <cz-input v-model="form.toVendorId" :error-messages="errors">
              </cz-input>
            </validation-provider>
          </cz-form-field>
        </v-card-text>
        <v-card-actions class="justify-center">
          <cz-button
            :title="$t('admin.modifyVendorIdDialog.actionButtonTitle')"
            color="primary"
            :min-width="150"
            :loading="loading"
            :disabled="invalid"
            type="submit"
          />
        </v-card-actions>
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import { getVendors, modifyVendorId } from '@/core/api';
import {
  CzDialog,
  CzButton,
  CzInput,
  CzFormField,
  CzAutocomplete
} from '@/components';
export default {
  name: 'ModifyVendorIdDialog',
  components: {
    CzDialog,
    CzButton,
    CzInput,
    CzFormField,
    CzAutocomplete
  },
  data() {
    return {
      form: {
        fromVendorId: '',
        toVendorId: ''
      },
      serachingVendors: false,
      vendors: [],
      loading: false
    };
  },
  methods: {
    async onVendorsSearch(value) {
      if (!value) {
        return;
      }
      try {
        this.serachingVendors = true;
        this.vendors = await getVendors({
          search: value
        });
      } finally {
        this.serachingVendors = false;
      }
    },
    async onSubmit() {
      try {
        this.loading = true;
        await modifyVendorId(this.form);
        this.$emit('success');
      } catch (error) {
        this.$emit('failed', error.message);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
